<template>
  <div class="px-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="form-heading text-lg-left">Profile Setting</h1>
    </div>
    <div class="row">
      <div class="col-md-6 ma-auto border">
        <div class="p-3 py-2">

          <div class="row mt-2">
            <div class="col-md-6">
              <label class="labels">First Name</label>
              <input type="text" class="form-control" v-model="user.first_name">
            </div>
            <div class="col-md-6">
              <label class="labels">Last Name</label>
              <input type="text" class="form-control" v-model="user.last_name">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <label class="labels">Email</label>
              <input disabled type="text" class="form-control" v-model="user.email">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <label class="labels">Phone Number</label>
              <div class="d-flex">
                  <div class="d-flex">
                    <div class="phone-code-input-background d-flex justify-center align-center text--black ">
                      <span class="pl-3 font-weight-regular account-tab-spacing-plus">+{{user.country_code}}</span>
                      <img class="pl-3 pr-1" src="https://my.staging.myonlinetherapy.com/assets/images/client/account/arrow_down.svg" alt="MyOnlineTherapy" />
                    </div>
                    <input v-model="user.phone" type="tel" class="form-control" readonly placeholder="123456789"/>

                  </div>
                <div class="flex-row justify-content-end account-tab-spacing-plus m-t-2 m-l-16">
                  <a class="bold-font fs-14 lh-17 pointer" @click="editMobile">Edit</a></div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <button class="btn mb-5" @click="updateProfile" type="button">Save Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationCode from "@/views/confirmationCode";
export default {
  name: "Profile",
  components: {
    ConfirmationCode
  },
  data() {
    return {
      user: {},
      showEditNumberModal: false,
      send_by: "mobile",
      country_code: "",
      region: "",
    }
  },
  created() {
    if (JSON.parse(localStorage.getItem('user')) !== null) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
  },
  methods: {
    editMobile() {
        this.$router.push('edit-phone-number')
    },
    updateProfile() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "update-profile", {
        user_id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
      }).then(response => {
        loader.hide();
        localStorage.setItem("user",JSON.stringify(response.data.user));
        this.$toastr("success", response.data.message, '');
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
  }
}
</script>

<style scoped>
.account-tab-spacing-plus {
  letter-spacing: 0.36px !important;
}
.phone-code-input-background {
  background: #e9ecef;
  border: 1px solid #ced4da;
}

</style>
